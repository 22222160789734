import React, { useContext, useRef } from "react";
import "./Navbar.css";
import { AuthContext } from "../../Contexts/AuthContext"; // Import your AuthContext
import { ShopContext } from "../../Contexts/ShopContext";
import logo from "../Assets/logo.png";
import cart_icon from "../Assets/cart_icon.png";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import nav_dropdown from "../Assets/nav_dropdown.png";

export const Navbar = () => {
  const { currentUser } = useContext(AuthContext); // Use AuthContext to access currentUser
  const { getTotalCartItems } = useContext(ShopContext);
  const menuRef = useRef(null);

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.target.classList.toggle("open");
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Logout successful
        console.log("Logout successful");
      })
      .catch((error) => {
        // An error occurred
        console.error("Error logging out:", error);
      });
  };

  const isAdmin = () => {
    // Check if the current user's email matches the admin email
    return currentUser && currentUser.email === "admin@yomakhana.com";
  };

  return (
    <div className="navbar">
      <div className="nav-logo">
        <Link style={{ textDecoration: "none" }} to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <img
        className="nav-dropdown"
        onClick={dropdown_toggle}
        src={nav_dropdown}
        alt=""
      />
      <ul ref={menuRef} className="nav-menu">
        <li>
          <Link style={{ textDecoration: "none" }} to="/">
            Home
          </Link>{" "}
        </li>
        <li>
          <Link style={{ textDecoration: "none" }} to="/products">
            Products
          </Link>{" "}
        </li>

        <li>
          <Link style={{ textDecoration: "none" }} to="/About">
            About Us
          </Link>{" "}
        </li>
        <li>
          <Link style={{ textDecoration: "none" }} to="/contact">
            Contact Us
          </Link>{" "}
        </li>
        {isAdmin() && (
          <li>
            <Link style={{ textDecoration: "none" }} to="/admin">
              Admin Panel
            </Link>{" "}
          </li>
        )}
      </ul>
      <div className="nav-login-cart">
        {currentUser ? (
          <>
            <button onClick={handleLogout}>Logout</button>
            <Link to="/cart">
              {" "}
              <img style={{ width: "60px" }} src={cart_icon} alt="" />
            </Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>
          </>
        ) : (
          <>
            <button>
              <Link style={{ textDecoration: "none" }} to="/login">
                Login
              </Link>
            </button>
            <Link to="/cart">
              {" "}
              <img style={{ width: "60px" }} src={cart_icon} alt="" />
            </Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>
          </>
        )}
      </div>
    </div>
  );
};
