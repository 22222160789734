import React from "react";
import "./Popup.css";

const Popup = ({ onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <h2>Order Placed!</h2>
        <p>Your order has been successfully placed.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
