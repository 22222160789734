import React, { useState } from "react";
// import { collection, addDoc } from "firebase/firestore";
// import { db } from "../../Firebase";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import "./Popup.css";

const PaymentInformationForm = ({
  formData,
  handleChange,
  prevStep,
  // cartItems,
  handleSubmit,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate("/");
  };

  return (
    <>
      <form className="loginsignup" onSubmit={handleSubmit}>
        <div className="loginsignup-container">
          <h2>Payment Information</h2>
          <div className="loginsignup-fields">
            <div>
              <label>Payment Method:</label>
              <select
                value={formData.paymentMethod}
                onChange={handleChange("paymentMethod")}
                required
                style={{ height: "60px", width: "100%" }}
              >
                <option value="">Select payment method</option>
                <option value="cod">Cash on delivery</option>
              </select>
            </div>
          </div>
          <div className="loginsignup-buttons">
            <button type="button" onClick={prevStep}>
              Back
            </button>
            <button type="submit">Confirm Order</button>
          </div>
        </div>
      </form>
      {showPopup && <Popup onClose={handleClosePopup} />}
    </>
  );
};

export default PaymentInformationForm;
