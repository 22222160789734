import React, { useContext, useEffect, useState } from "react";
import "./Popular.css";
import { ShopContext } from "../../Contexts/ShopContext";
import { Item } from "../Item/Item";

export const Popular = () => {
  const { products } = useContext(ShopContext);
  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    // Sort products by new_price in descending order
    const sortedProducts = products.sort((a, b) => b.newPrice - a.newPrice);
    // Slice the first 4 products
    const topProducts = sortedProducts.slice(0, 4);
    setPopularProducts(topProducts);
  }, [products]);

  return (
    <div className="popular">
      <h1>POPULAR PRODUCTS</h1>
      <hr />
      <div className="popular-item">
        {popularProducts.map((product, i) => (
          <Item
            key={i}
            id={product.id}
            name={product.name}
            image={product.image}
            new_price={product.newPrice}
            old_price={product.oldPrice}
          />
        ))}
      </div>
    </div>
  );
};
