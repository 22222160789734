import React from "react";
import "./PersonalDetails.css"; // Import Contact.css for styling

const PersonalDetailsForm = ({ formData, setFormData, nextStep }) => {
  const handleChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform form validation here before proceeding to the next step
    nextStep();
  };

  return (
    <form className="loginsignup" onSubmit={handleSubmit}>
      {" "}
      {/* Apply loginsignup class */}
      <div className="loginsignup-container">
        {" "}
        {/* Apply loginsignup-container class */}
        <h2>Personal Details</h2>
        <div className="loginsignup-fields">
          {" "}
          {/* Apply loginsignup-fields class */}
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={formData.name}
              onChange={handleChange("name")}
              required
            />
          </div>
          <div>
            <label>Phone Number:</label>
            <input
              type="tel"
              value={formData.phoneNumber}
              onChange={handleChange("phoneNumber")}
              required
            />
          </div>
          <div>
            <label>Permanent Address:</label>
            <input
              type="text"
              value={formData.permanentAddress}
              onChange={handleChange("permanentAddress")}
              required
            />
          </div>
        </div>
        <button type="submit">Next</button>
      </div>
    </form>
  );
};

export default PersonalDetailsForm;
