import React from "react";
import "./Hero.css";
// import hand_icon from "../Assets/hand_icon.png";
import arrow_icon from "../Assets/arrow.png";
import { Link } from "react-router-dom";
export const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-left">
        <h2>Yomakhana - The superfood of Mithila</h2>
        <div>
          {/* <div className="hero-hand-icon">
            <p>new</p>
            <img src={hand_icon} alt="" />
          </div> */}

          <p>A place where cuisine meets the tradition</p>
        </div>
        <Link style={{ textDecoration: "none" }} to="/products">
          <div className="hero-latest-btn">
            <div>Explore Products</div>
            <img src={arrow_icon} alt="" />
          </div>
        </Link>
      </div>
      {/* <div className="hero-right">
        <img src={hero_image} alt="" />
      </div> */}
    </div>
  );
};
