import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ShopContext } from "../Contexts/ShopContext";
import { Breadcrum } from "../Components/Breadcrums/Breadcrum.jsx";
import { ProductDisplay } from "../Components/ProductDisplay/ProductDisplay.jsx";
import { RelatedProduct } from "../Components/RelatedProducts/RelatedProduct.jsx";

export const Product = () => {
  const { products } = useContext(ShopContext);
  const { productId } = useParams();
  const product = products.find((product) => product.id === productId);

  return (
    <div>
      <Breadcrum product={product} />
      <ProductDisplay product={product} />
      {/* <DescriptionBox /> */}
      <RelatedProduct productId={product.id} />
    </div>
  );
};
