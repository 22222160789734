import React from "react";
import "./CheckoutModal.css";
import CheckoutForm from "./CheckoutForm";

const CheckoutModal = ({ onClose, cartItems }) => {
  return (
    <div className="checkout-modal">
      <div className="checkout-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Checkout</h2>
        <CheckoutForm cartItems={cartItems} />
      </div>
    </div>
  );
};

export default CheckoutModal;
