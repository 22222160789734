import React, { useContext } from "react";
import "./RelatedProduct.css";
import { ShopContext } from "../../Contexts/ShopContext";
import { Item } from "../Item/Item";

export const RelatedProduct = ({ productId }) => {
  const { products } = useContext(ShopContext);

  const sortedProducts = products
    .slice()
    .sort((a, b) => b.newPrice - a.newPrice);

  const relatedProducts = sortedProducts
    .filter((product) => product.id !== productId)
    .slice(0, 4);

  return (
    <div className="relatedproducts">
      <h1>Related Products</h1>
      <hr />
      <div className="relatedproducts-item">
        {relatedProducts.map((product, i) => (
          <Item
            key={i}
            id={product.id}
            name={product.name}
            image={product.image}
            new_price={product.newPrice}
            old_price={product.oldPrice}
          />
        ))}
      </div>
    </div>
  );
};
