import React from "react";
import "./Breadcrum.css";
import arrow_icon from "../Assets/breadcrum_arrow.png";

export const Breadcrum = (props) => {
  const { product } = props;
  const productName = product ? product.name : ""; // Check if product is defined
  return (
    <div className="breadcrum">
      HOME <img src={arrow_icon} alt="" /> SHOP <img src={arrow_icon} alt="" />{" "}
      {productName}
    </div>
  );
};
