import React from "react";
import { Hero } from "../Components/Hero/Hero";
import { Popular } from "../Components/Popular/Popular";
import { Supported } from "../Components/Popular/Supported.jsx";
// import { Offers } from "../Components/Offers/Offers";
// import { NewCollections } from "../Components/NewCollections/NewCollections";
import { NewsLetter } from "../Components/NewsLetter/NewsLetter";

export const Shop = () => {
  return (
    <div>
      <Hero />
      <Popular />
      {/* <Offers /> */}
      {/* <NewCollections /> */}
      <Supported />
      <NewsLetter />
    </div>
  );
};
