import React from "react";
import "./Supported.css";
import SBI from "../Assets/SBI.webp";
import YFI from "../Assets/YFI.webp";
import UW from "../Assets/logo1.png";
// import hindi from "../Assets/hindi.png";
import madhubani from "../Assets/mithila_banner.webp";
export const Supported = () => {
  return (
    <div className="supported">
      <div className="images">
        <img src={madhubani} alt="" />
      </div>
      <h1>SUPPORTED BY</h1>
      <hr className="supported-hr" />
      <div className="supported-item">
        <img className="img1" src={YFI} alt="" />
        <img style={{ marginBottom: "50px" }} src={SBI} alt="" />
      </div>
      <h1>PARTNERED BY</h1>
      <hr className="supported-hr" />
      <div className="supported-item">
        <img src={UW} alt="Udyamwell" />
      </div>
    </div>
  );
};
