import React, { useState } from "react";
import PersonalDetailsForm from "./PersonalDetailsForm";
import PaymentInformationForm from "./DeliveryAddressForm";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { useNavigate } from "react-router-dom";
const CheckoutForm = ({ cartItems, setCartItems }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const getProductDetails = async (productId) => {
    const docRef = doc(db, "products", productId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const productData = docSnap.data();
      return productData.name; // Assuming the product name is stored in a field called "name"
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("cartItems:", cartItems);

    // Ensure cartItems is populated and has valid data
    if (!cartItems || Object.keys(cartItems).length === 0) {
      console.error("Cart items are not available or empty.");
      return;
    }

    // Fetch product details for each product in the cartItems array
    const productDetails = await Promise.all(
      Object.keys(cartItems).map(async (productId) => {
        const productName = await getProductDetails(productId);
        if (productName) {
          return { name: productName, quantity: cartItems[productId] };
        }
        return null;
      })
    );

    console.log("productDetails:", productDetails);

    // Filter out any null values
    const filteredProductDetails = productDetails.filter((product) => product);

    // Combine form data with product details
    const orderData = { ...formData, cartItems: filteredProductDetails };

    // Save order data to Firebase Firestore
    try {
      const docRef = await addDoc(collection(db, "orders"), orderData);
      console.log("Document written with ID: ", docRef.id);

      navigate("/");
      alert("Order placed successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  switch (step) {
    case 1:
      return (
        <PersonalDetailsForm
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
        />
      );
    case 2:
      return (
        <PaymentInformationForm
          formData={formData}
          handleSubmit={handleSubmit}
          prevStep={prevStep}
          handleChange={handleChange}
        />
      );

    default:
      return null;
  }
};

export default CheckoutForm;
