import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../Firebase";
import "./UploadProductForm.css"; // Import CSS for styling

const UploadProductForm = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "orders"));
        const orders = [];
        querySnapshot.forEach((doc) => {
          const orderData = doc.data();
          const cartItems = orderData.cartItems;
          const products = [];
          for (const item of cartItems) {
            const productName = item.name; // Assuming the product name is stored in a field called "name"
            const productQuantity = item.quantity;
            products.push({ productName, productQuantity });
          }
          orders.push({ id: doc.id, products, ...orderData });
        });
        setOrderDetails(orders);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, []);

  const [contactResponses, setContactResponses] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = [];
        querySnapshot.forEach((doc) => {
          productsData.push({ id: doc.id, ...doc.data() });
        });
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);
  useEffect(() => {
    const fetchContactResponses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "contactResponses"));
        const responses = [];
        querySnapshot.forEach((doc) => {
          responses.push(doc.data());
        });
        setContactResponses(responses);
      } catch (error) {
        console.error("Error fetching contact responses:", error);
      }
    };

    fetchContactResponses();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    newPrice: "",
    oldPrice: "",
    desc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageDataUrl = reader.result;
      setFormData({ ...formData, image: imageDataUrl });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "products"), formData);
      console.log("Product uploaded with ID: ", docRef.id);
      alert("Product Uploaded Successfully");
      setFormData({
        name: "",
        image: "",
        newPrice: "",
        oldPrice: "",
        desc: "",
      });
      console.log(formData);
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };
  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, "products", productId));
      console.log("Product deleted successfully");
      // Refresh the product list after deletion
      // You may need to fetch products again from the database to update the UI
      setProducts(products.filter((product) => product.id !== productId));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  return (
    <div className="upload-product-form">
      <h1>Upload Products</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <label>
          Image Upload:
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </label>
        <label>
          New Price:
          <input
            type="text"
            name="newPrice"
            value={formData.newPrice}
            onChange={handleChange}
          />
        </label>
        <label>
          Old Price:
          <input
            type="text"
            name="oldPrice"
            value={formData.oldPrice}
            onChange={handleChange}
          />
        </label>
        <label>
          Description:
          <textarea name="desc" value={formData.desc} onChange={handleChange} />
        </label>
        <button type="submit">Upload Product</button>
      </form>
      {/* Display uploaded products */}
      <div className="order-details">
        <h1>Uploaded Products</h1>
        <ul>
          {products.map((product) => (
            <li key={product.id}>
              <div>
                <strong>Name:</strong> {product.name},{" "}
                <strong>New Price:</strong> {product.newPrice},{" "}
                <strong>Old Price:</strong> {product.oldPrice},{" "}
                <strong>Description:</strong> {product.desc}
              </div>
              <div>
                <img
                  style={{ width: "100px" }}
                  src={product.image}
                  alt={product.name}
                />
              </div>
              <button onClick={() => handleDeleteProduct(product.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
      {/* Additional sections for contact responses and order details */}
      <div className="order-details">
        <h1>Contact Form Responses</h1>
        <ul>
          {contactResponses.map((response, index) => (
            <li key={index}>
              <strong>Name:</strong> {response.name}, <strong>Email:</strong>{" "}
              {response.email}, <strong>Message:</strong> {response.password}
            </li>
          ))}
        </ul>
      </div>
      <div className="order-details">
        <h1>Order Details</h1>
        <ul>
          {orderDetails.map((order, index) => (
            <li key={index}>
              <strong>Order ID:</strong> {order.id}, <strong>Name:</strong>{" "}
              {order.name}, <strong>Buyer Address:</strong>{" "}
              {order.permanentAddress}, <strong>Phone:</strong>{" "}
              {order.phoneNumber}, <strong>Product:</strong>{" "}
              {order.products.map((product) => (
                <span>
                  {product.productName} (Quantity: {product.productQuantity})
                </span>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UploadProductForm;
