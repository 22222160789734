import React, { useContext } from "react";
import "./CSS/ShopCategory.css";
import { ShopContext } from "../Contexts/ShopContext";
import Item from "../Components/Item/Item";
import BannerCarousel from "../Components/BannerCaraousel/BannerCaraousel";
import banner1 from "../../src/Components/Assets/banner1.webp";
import banner2 from "../../src/Components/Assets/banner2.webp";
const banners = [
  {
    image: banner1,
    title: "Welcome to our store",
    description: "Discover amazing deals on our products",
  },
  {
    image: banner2,
    title: "New arrivals",
    description: "Check out our latest products",
  },
  // Add more banner objects as needed
];
export const ShopCategory = (props) => {
  const { products } = useContext(ShopContext);
  return (
    <div className="shop-category">
      <BannerCarousel banners={banners} />;
      <div className="shopcategory-products">
        {products.map((item, i) => {
          return (
            <Item
              key={i}
              id={item.id}
              name={item.name}
              image={item.image}
              new_price={item.newPrice}
              old_price={item.oldPrice}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ShopCategory;
