import React, { useContext, useEffect, useState } from "react";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Contexts/ShopContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { Link, useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase";

export const ProductDisplay = () => {
  const { addToCart } = useContext(ShopContext);
  const { currentUser } = useContext(AuthContext);
  const [product, setProduct] = useState(null);
  const { productId } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDocs(collection(db, "products"));
        const products = productDoc.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const foundProduct = products.find((prod) => prod.id === productId);
        setProduct(foundProduct);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [productId]);
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    loadingText: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333",
    },
  };
  if (!product) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingText}>Loading...</div>
      </div>
    );
  }

  return (
    <div className="productdisplay">
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
        </div>
        <div className="productdisplay-img">
          <img className="productdisplay-main-img" src={product.image} alt="" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product.name}</h1>
        <div className="productdisplay-right-star">
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_dull_icon} alt="" />
          <p>(122)</p>
        </div>
        <div className="productdisplay-right-prices">
          <div className="productdisplay-right-price-old">
            ₹{product.oldPrice}
          </div>
          <div className="productdisplay-right-price-new">
            ₹{product.newPrice}
          </div>
        </div>
        <div className="productdisplay-right-description">{product.desc}</div>

        {currentUser ? (
          <button onClick={() => addToCart(product.id)}>ADD TO CART</button>
        ) : (
          <Link to="/login" style={{ textDecoration: "none" }}>
            <button>Login to Buy</button>
          </Link>
        )}
      </div>
    </div>
  );
};
