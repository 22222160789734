import React, { useState, useEffect } from "react";
import "./BannerCarousel.css";

const BannerCarousel = ({ banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === banners.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="banner-carousel">
      {banners.map((banner, index) => (
        <div
          key={index}
          className={`carousel-slide ${index === currentSlide ? "active" : ""}`}
          style={{
            background: `url(${banner.image})`,
            backgroundSize: "cover",
          }}
        >
          <div className="carousel-content">
            <h2 style={{ textAlign: "left" }}>{banner.title}</h2>
            <p>{banner.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BannerCarousel;
