import React from "react";
import "./Footer.css";
import footer_logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
export const Footer = () => {
  return (
    <div className="footercontainer">
      <div className="footer">
        <div className="footer-logo">
          <img src={footer_logo} alt="" />
        </div>
        <ul className="footer-links" style={{ padding: "0px" }}>
          <li>
            <Link className="footer-link" to="/products">
              Products
            </Link>
          </li>

          <li>
            <Link className="footer-link" to="/About">
              About
            </Link>
          </li>
          <li>
            <Link className="footer-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
        <div className="footer-social-icon">
          <div className="footer-icons-container">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.linkedin.com/in/yo-makhana-474557228/?trk=people_directory&originalSubdomain=in"
            >
              <InstagramIcon />
            </a>
          </div>
          <div className="footer-icons-container">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.linkedin.com/in/yo-makhana-474557228/?trk=people_directory&originalSubdomain=in"
            >
              <XIcon />
            </a>
          </div>
          <div className="footer-icons-container">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://m.facebook.com/Yomakhana.Foxnuts/"
            >
              <FacebookIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>Copyright @ 2024 - Yomakhana</p>

        <a
          style={{ textDecoration: "none", color: "white" }}
          href="https://www.instagram.com/the.noobdesigner?igsh=MXdrYjFnb3l4MzEwNw=="
        >
          <p>Developed by @The.NoobDesigner</p>
        </a>
      </div>
    </div>
  );
};
