import React, { useContext, useState } from "react";
import CheckoutModal from "../Checkout Form/CheckoutModal";
import "./CartItems.css";
import { ShopContext } from "../../Contexts/ShopContext";
import remove_icon from "../Assets/cart_cross_icon.png";

export const CartItems = () => {
  const { getTotalCartAmount, products, cartItems, removeFromCart } =
    useContext(ShopContext);
  const total = getTotalCartAmount();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const handleProceedToCheckout = () => {
    setShowCheckoutModal(true);
  };

  const handleCloseModal = () => {
    setShowCheckoutModal(false);
  };

  const hasItemsInCart = Object.values(cartItems).some(
    (quantity) => quantity > 0
  );

  return (
    <div className="cartitem">
      <div className="cartitem-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {products &&
        products.map((product) => {
          if (cartItems[product.id] > 0) {
            return (
              <div key={product.id}>
                <div className="cartitem-format cartitem-format-main">
                  <img
                    src={product.image}
                    alt=""
                    className="carticon-product-icon"
                  />
                  <p>{product.name}</p>
                  <p>₹{product.newPrice}</p>
                  <button className="cartitem-quantity">
                    {cartItems[product.id]}
                  </button>
                  <p>₹{product.newPrice * cartItems[product.id]}</p>
                  <img
                    className="cartitem-remove-icon"
                    src={remove_icon}
                    onClick={() => removeFromCart(product.id)}
                    alt=""
                  />
                </div>
                <hr />
              </div>
            );
          }
          return null;
        })}
      <div className="cartitem-down">
        <div className="cartitem-total">
          <h1>Cart Total</h1>
          <div>
            <div className="cartitem-totalitem">
              <p>Sub Total</p>
              <p>₹ {total}</p>
            </div>
            <hr />
            <div className="cartitem-totalitem">
              <p>Shipping Fee</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitem-totalitem">
              <h3>Total</h3>
              <h3>₹{total}</h3>
            </div>
          </div>
          {hasItemsInCart && (
            <button onClick={handleProceedToCheckout}>
              PROCEED TO CHECKOUT
            </button>
          )}
        </div>
        <div className="cartitem-promocode">
          <p>If you have a promo code, Enter it here</p>
          <div className="cartitem-promobox">
            <input type="text" placeholder="Promo Code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
      {showCheckoutModal && (
        <CheckoutModal onClose={handleCloseModal} cartItems={cartItems} />
      )}
    </div>
  );
};
