import React, { useState } from "react";
import "../Pages/CSS/About.css";
import founder1 from "../Components/Assets/founder1.webp";
import founder2 from "../Components/Assets/founder2.webp";
import img1 from "../Components/Assets/img1.webp";
import img2 from "../Components/Assets/img2.webp";
import img3 from "../Components/Assets/img3.webp";
import img4 from "../Components/Assets/img4.webp";
const images = [img1, img2, img3, img4];
const About = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container">
      <h1>About YoMakhana</h1>

      <div className="company-info">
        <h2>Our Story</h2>
        <p>
          Yomakhana was founded with a vision to revolutionize snacking by
          combining traditional flavors with modern technology. Our journey
          began with a simple idea – to introduce the world to the goodness of
          Makhana, a superfood native to the Seemanchal regions of Bihar.
        </p>
        <p>
          At Yomakhana, we are committed to providing healthy and guilt-free
          snacking options that not only nourish the body but also celebrate the
          rich cultural heritage of India. Our products are meticulously crafted
          using the finest quality ingredients, ensuring every bite is a delight
          to the senses.
        </p>
      </div>
      <h1>Events</h1>
      <div className="container2">
        {images.map((image, index) => (
          <div
            key={index}
            className="box"
            onClick={() => handleImageClick(image)}
          >
            <img
              style={{
                border: "8px solid #000000",

                padding: "3px",
                margin: "5px",
              }}
              src={image}
              alt={`I ${index + 1}`}
            />
          </div>
        ))}
        {selectedImage && (
          <div className="popup-overlay" onClick={handleClosePopup}>
            <div className="popup-content">
              <img src={selectedImage} alt="Popup" />
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="founder">
        <h1>Meet Our Founders</h1>

        <p>
          Yomakhana was founded by Ankit Mishra and Varsha Jha, two individuals
          driven by a passion for innovation and a desire to make a positive
          impact in society. With diverse backgrounds in Yog , Naturopathy ,
          Health Sector , Process industries , Political consultancy and
          Development sector our founders bring a wealth of experience and
          expertise to the table.
        </p>
        <p>
          Together, we are on a mission to build a decentralized rural economy
          that provides indigenous solutions to the modern world while staying
          rooted in our cultural heritage. Join us on our journey to redefine
          snacking with YoMakhana – where tradition meets innovation, one
          crunchy bite at a time!
        </p>
        <div className="images">
          <img
            style={{
              border: "8px solid #000000",

              padding: "3px",
              margin: "5px",
            }}
            src={founder1}
            alt=""
          />
          <div className="about_founder">
            <h1>Ankit Mishra </h1>

            <p>
              Having an experience in process industries and steam engineering,
              I aspired to build a startup which uses technology for cultural
              products and what better than Makhana, a superfood from my home
              state of seemanchal regions in Bihar. I have worked in political
              consultancy and public policy domains and have realized to build a
              decentralized rural economy which provides indigenous solutions to
              the modern world and keep them culturally rooted
            </p>
          </div>
        </div>
        <div className="images">
          <img
            style={{
              border: "8px solid #000000",

              padding: "3px",
              margin: "5px",
            }}
            src={founder2}
            alt=""
          />
          <div className="about_founder">
            <h1>Varsha Jha</h1>

            <p>
              I have experience of working in development sector and have worked
              with many Philanthropic organizations. I have switched my sector
              and currently I am a practising yoga teacher and have been curing
              people with breathwork and yogic therapies. Belonging from
              Madhubani, I have grown up being attached to Mithila way of life
              and cuisines so this startup is my offering to the people to have
              a guilt free and healthy snacking experience
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
