import React, { useState } from "react";
import "./CSS/LoginSignup.css";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from "../Firebase";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // Add state for checkbox
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth(firebaseApp);
    if (!isChecked) {
      alert("Please agree to the terms of use & privacy policy.");
      return; // Prevent form submission
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect user to the dashboard or any other page upon successful login
      navigate("/"); // Redirect to home page after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="loginsignup">
      <div className="loginsignup-container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="loginsignup-fields">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="loginsignup-agree">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <p>By continuing, I agree to the terms of use & privacy policy.</p>
          </div>
          <button type="submit">Continue</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <p className="loginsignup-login">
          Don't have an account? <Link to="/signup">Sign Up Here</Link>
        </p>
      </div>
    </div>
  );
};
