import "./App.css";
import { Navbar } from "../src/Components/Navbar/Navbar.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShopCategory } from "./Pages/ShopCategory";
import { Shop } from "./Pages/Shop";
import { Product } from "./Pages/Product";
import { Cart } from "./Pages/Cart";
import { Signup } from "../src/Pages/Signup.jsx";
import { Login } from "../src/Pages/Login.jsx";
import { Footer } from "./Components/Footer/Footer";

import Contact from "./Pages/Contact";
import About from "./Pages/About";
import UploadProduct from "./Components/Upload/Upload.jsx";
import CheckoutForm from "./Components/Checkout Form/CheckoutForm.jsx";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/products" element={<ShopCategory />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="product" element={<Product />}>
            <Route path=":productId" element={<Product />} />
          </Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/checkout" element={<CheckoutForm />}></Route>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<UploadProduct />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
