import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCxfIHbd-R1Jhjot4rrgq1PS2Bcb5k0Byc",
  authDomain: "yo-makhana.firebaseapp.com",
  projectId: "yo-makhana",
  storageBucket: "yo-makhana.appspot.com",
  messagingSenderId: "173256732273",
  appId: "1:173256732273:web:4239f5a5a7864029c135d0",
  measurementId: "G-Q6KZCKGJP7",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = app.storage;
// const db = app.getFirestore;

export { db, storage };
export default app;
